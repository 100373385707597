import React, { Component, useCallback, useEffect, useState } from 'react'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'
import './OnboardCard.sass'
import BiometricComponent from './BiometricComponent/BiometricComponent'
import SuccessHandler from './StatesComponents/SuccessHandler'
import ErrorHandler from './StatesComponents/ErrorHandler'
import LoadingHandler from './StatesComponents/LoadingHandler'
import MobileHandler from './StatesComponents/MobileHandler'
import ErrorCapHandler from './StatesComponents/ErrorCapHandler'
import ErrorGlassesHandler from './StatesComponents/ErrorGlassesHandler'
import ErrorInvalidHandler from './StatesComponents/ErrorInvalidHandler'

interface Props {
  token: (token: any) => void,
  onResponse: (response: any) => void
}

function FaceOnboard({ token, onResponse }: Props) {
  const [photoState, setphotoState] = useState<string>('')

  const handleResponse = useCallback((message: string) => {
    if(message === 'success'){

        return onResponse({ step: 'facesuccess' })

    }
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
  }, [])

  return (
    <>
      <div className={`${photoState} card proccess-card`}>
        {photoState === 'success' ? (
          <>
            <SuccessHandler />
          </>
        ) : photoState === 'error' ? (
          <ErrorHandler />
        ) : photoState === 'invalid' ? (
          <ErrorInvalidHandler onResponse={handleResponse} />
        ) : photoState === 'loading' ? (
          <LoadingHandler />
        ) : photoState === 'glasses' ? (
          <ErrorGlassesHandler onResponse={handleResponse} />
        ) : photoState === 'cap' ? (
          <ErrorCapHandler onResponse={handleResponse} />
        ) : isMobile || photoState === 'mobile' ? (
          <BiometricComponent
            token={token}
            onResponse={handleResponse}
          ></BiometricComponent>
        ) : (
          <MobileHandler />
        )}
      </div>
      <ul className="step-counter">
        <li className="active"></li>
        <li className="active"></li>
        <li className="active"></li>
        <li className="active"></li>
      </ul>
    </>
  )
}

export default FaceOnboard
