import React, { useEffect, useState } from 'react'
import qrCode from './../../images/qr_code.png'
import { BASE } from '../../basedata/constants'

import './ErrorsHandlers.sass'

const MobileHandler = () => {

  const makeid = (length: any) => {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  const verifyBiometry = (token: any) => {


    fetch(`${BASE.user_biometry.base_url}${BASE.user_biometry.document_status}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        "token": token
      })
    })
      .then((resg) => resg.json())
      .then((response) => {

        console.log('- > > > >', response.userInformation?.callbackURL)

        if (response.message === 'success') {
          if (response.userInformation && response.userInformation?.callbackURL?.length > 0) {
            console.log('oiee')
            window.sessionStorage.setItem('callbackurl', response.userInformation.callbackURL)
            // return window.location.href = `${response.userInformation.callbackURL}`
            window.location.reload()
          } else {
            window.location.reload()
          }
        } else {
          setTimeout(() => {
            verifyBiometry(token)
          }, 5000);
        }

      })
      .catch((error) => {
        return false
      })

  }

  const [currentUrl, setCurrentUrl] = React.useState(window.location.origin+window.location.pathname)
  const [userToken, setUserToken] = React.useState('')
  const [originCompany, setOriginCompany] = React.useState('')
  const [credencialsCheck, setCredencialsCheck] = React.useState('true')

  React.useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
      setUserToken(window.sessionStorage.getItem('token') + '')
      setOriginCompany(window.sessionStorage.getItem('orc') + '')
    }
  }, [])

  React.useEffect(() => {
    verifyBiometry(window.sessionStorage.getItem('token'))
  }, [])




  return (
    <>
      <h1>
        <span>Vamos </span>começar!
      </h1>
      <hr />
      <p>
        Para uma melhor experiência, pedimos que acesse de seu aparelho celular.
      </p>
      <p>Escaneie este QR CODE com seu celular ou acesse o link abaixo:</p>
      <img
        src={
          `https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=${currentUrl}?token=` + userToken + `	%26orc=` + originCompany+credencialsCheck}
        className="qr-code"
        alt="QR Code"
      />
      <a
        className="qr-link"
        href={`${currentUrl}?token=${userToken}&orc=` + originCompany+credencialsCheck}
      >
        {`${currentUrl}?token=${userToken}&orc=` + originCompany+credencialsCheck}
      </a>
    </>
  )
}

export default MobileHandler
