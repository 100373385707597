import React, { useEffect, useState } from 'react'

const SuccessHandler = () => {
  const redirect = window.sessionStorage.getItem('callbackurl')

  window.sessionStorage.removeItem('token')


  return (
    <>
      <h1>
        <span>Tudo </span>pronto!
      </h1>
      <hr />
      <p className="text-center">
        Seu cadastro facial foi realizado com sucesso
        <br />
        <br />
        Obrigado!
      </p>
    </>
  )
}

export default SuccessHandler
